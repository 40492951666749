import { ConsentType } from '../interfaces/Consent';
import coreApiService from './core.service';

const REGISTER = 'auth/sign-up';
const LOGIN = 'auth/sign-in';
const GUEST_LOGIN = 'auth/guest/sign-in';
const LOGOUT = 'auth/sign-out';
const REFRESH = 'auth/token/refresh';
const FORGOT_PASSWORD = 'auth/forgot-password';
const VERIFY = 'auth/verify';
const USER = 'user';
const CHANGE_PASSWORD = 'user/password';
const RESET_PASSWORD = 'auth/reset-password';
const CONSENT = 'consent';

export const authApi = {
    updateNotification: (type: ConsentType, active: boolean) =>
        coreApiService.postAuth(CONSENT, {
            consents: [{ type, active }],
        }),
    updateNotificationsModal: (consents: { type: ConsentType; active: boolean }[], email?: string) =>
        coreApiService.postAuth(`${CONSENT}/newsletter`, {
            consents,
            email,
        }),
    updateNotificationsMyAccount: (consents: { type: ConsentType; active: boolean }[], email?: string) =>
        coreApiService.postAuth(`${CONSENT}`, {
            consents,
            email,
        }),
    getNotifications: () => coreApiService.getAuth(`${CONSENT}/list`),
    getUser: () => coreApiService.getAuth(USER),
    updateUser: (data: any) => coreApiService.patchAuth(USER, data),
    register: (data: any) => coreApiService.postAuth(`${REGISTER}?project=sw`, data),
    verify: (token: any) => coreApiService.getAuth(VERIFY, { token }),
    login: (data: any) => coreApiService.postAuth(LOGIN, data),
    guestLogin: (token: string | string[]) => coreApiService.postAuth(GUEST_LOGIN, { token }),
    forgotPassword: (email: string) => coreApiService.postAuth(`${FORGOT_PASSWORD}?project=sw`, { email }),
    changePassword: (currentPassword: string, newPassword: string) =>
        coreApiService.patchAuth(CHANGE_PASSWORD, { currentPassword, newPassword }),
    resetPassword: (token: string, password: string) =>
        coreApiService.postAuth(`${RESET_PASSWORD}?project=sw`, { token, password }),
    logout: () => coreApiService.postAuth(LOGOUT, {}),
    refresh: (refreshToken: string) => coreApiService.postAuth(REFRESH, { refresh_token: refreshToken }, {}, {}),
};
