import coreApiService from './core.service';

export const orderApi = {
    create: (data: Object) => coreApiService.post('eshop/order', data),
    detail: (key: string) => coreApiService.get(`eshop/order/partial/${key}`),
    getPaymentRedirectUrl: (key: string, url?: string) => coreApiService.get(`eshop/order/${key}/payment/gopay`, { url }),
    getPaymentStatus: (paymentId: string) => coreApiService.get(`eshop/order/payment/gopay/status`, { id: paymentId }),
    getOrders: () => coreApiService.get(`pv/eshop/order/list`),
    getOrder: (key: string) => coreApiService.get(`pv/eshop/order/${key}`),
    calculatePrices: (cartKey: string | 0, donation?: number, discountCode?: string) =>
        coreApiService.post(`eshop/order/calculate-price`, {
            cartKey,
            ...(donation && { donation }),
            ...(discountCode && { discountCode }),
        }),
};
