import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useRouter } from 'next/router';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import { publicAssets } from '@utils/url.utils';
import { ROUTES } from '../../constants';
import {
    About,
    Benefit,
    BenefitLabel,
    BenefitNumber,
    Benefits,
    Description,
    HeroImage,
    Title,
    Top,
    Wrapper,
} from './Hero.elements';

export interface HeroProps {}

const HeroTemplate: FC<HeroProps> = () => {
    const { t } = useTranslations();

    const router = useRouter();

    return (
        <Wrapper>
            <Top>
                <HeroImage
                    src={publicAssets('/home_hero.png')}
                    width={0}
                    height={0}
                    alt={t('homepage.hero.title')}
                    loader={({ src }: any) => src}
                />
                <About>
                    <Title>{t('homepage.hero.title')}</Title>
                    <Description>{t('homepage.hero.description')}</Description>
                    <ButtonTemplate
                        onClick={() => router.push({ pathname: ROUTES.TRAININGS })}
                        text={t('homepage.hero.button.primary')}
                        marginBottom={15}
                    />
                </About>
            </Top>
            <Benefits>
                <Benefit>
                    <BenefitNumber>{t('homepage.benefit1.number')}</BenefitNumber>
                    <BenefitLabel dangerouslySetInnerHTML={{ __html: t('homepage.benefit1.label') }} />
                </Benefit>
                <Benefit>
                    <BenefitNumber>{t('homepage.benefit2.number')}</BenefitNumber>
                    <BenefitLabel dangerouslySetInnerHTML={{ __html: t('homepage.benefit2.label') }} />
                </Benefit>
                <Benefit>
                    <BenefitNumber>{t('homepage.benefit3.number')}</BenefitNumber>
                    <BenefitLabel dangerouslySetInnerHTML={{ __html: t('homepage.benefit3.label') }} />
                </Benefit>
                <Benefit>
                    <BenefitNumber>{t('homepage.benefit4.number')}</BenefitNumber>
                    <BenefitLabel dangerouslySetInnerHTML={{ __html: t('homepage.benefit4.label') }} />
                </Benefit>
            </Benefits>
        </Wrapper>
    );
};

export default HeroTemplate;
